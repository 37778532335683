import React from 'react'
import '../../scss/pages/profiles.scss'

import { siteMetadata } from '../../../gatsby-config'
import ProfileLayout from 'components/layout/profiles'
import Meta from 'components/meta'
import peopleData from '../../../content/people.json';

const person = peopleData.people[5];

const Hamm = ({ data, location }) => (
  <ProfileLayout location={location} profile_name="Peter R. Hamm" profile_key="hamm">
      <Meta site={siteMetadata} title="Peter R. Hamm" />
	  	<div className="row">
			<div className="col-md-9 pr-md-5">
				<h4>About</h4>

				<p>Peter represents individual and corporate clients across a range of criminal and quasi-criminal matters at both the trial and appellate levels. Since joining the firm in 2013, Peter has appeared before all levels of court in Ontario and has appeared before various administrative tribunals, including the Law Society Tribunal representing lawyers in disciplinary proceedings, and as a prosecutor at the Ontario College of Trades.</p>

				<p>Before joining Greenspan Humphrey Weinstein LLP, Peter lived and worked in Dubai, U.A.E. where he was an associate in the corporate practice of a national Canadian law firm.  Prior to becoming a lawyer, Peter was employed by several non-governmental organizations specializing in human rights, including at the International Bar Association’s Human Rights Institute and Penal Reform International. </p>

				<p>Peter earned a Bachelor of Science with Honours from the London School of Economics and Political Science in 2006 where he studied International Relations. He received a Graduate Diploma in Law with Honours from the BPP College of Law in London, England in 2007. He subsequently completed the Legal Practitioners Course in 2008 and graduated with a Masters of Law with distinction in 2009, following which he qualified as a solicitor in England and Wales.</p>

				<p>Peter is a member of the Advocate’s Society, the Toronto Lawyer’s Association, the Ontario Bar Association, and the Criminal Lawyers’ Association.</p>
			</div>
			<div className="col-md-3 contact-card">
				<h4 className="text-primary">Get in touch</h4>
				<p className="text-primary">15 Bedford Rd.<br/>
				Toronto, Ontario <br/>
				M5R 2J7</p>

				<p><a className="d-flex align-items-center" href={"tel:"+person.phone_code}><img className="icon pr-1" src="/img/icons/blue-phone.png"/>{person.phone}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.email}><img className="icon pr-1" src="/img/icons/blue-email.png"/>{person.email}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.assistant_contact}><img className="icon" src="/img/icons/blue-whatsapp.png"/>Contact {person.short_name}’s Assistant</a></p>
				{/* <p><a className="d-flex align-items-center" ><img className="icon" src="/img/icons/blue-twitter.png"/>Follow {person.short_name} on Twitter</a></p> */}

			</div>
		</div>	  
  </ProfileLayout>
)
export default Hamm
